<template>
  <div class="topbar-item">
    <template v-if="!isApplicant">
      <div id="kt_quick_panel_toggle" class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary">
        <i class="flaticon2-notification" />
        <span class="pulse-ring" />
      </div>

      <!-- begin::Quick Panel -->
      <div id="kt_quick_panel" ref="kt_quick_panel" class="offcanvas offcanvas-right p-10" style="overflow: hidden;">
        <!--begin::Header-->
        <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
          <h3 class="font-weight-bold m-0">Recent Activities</h3>
          <div class="offcanvas-close mt-n1 pr-5">
            <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close">
              <i class="ki ki-close icon-xs text-muted" />
            </a>
          </div>
        </div>
        <!--end::Header-->

        <perfect-scrollbar class="offcanvas-content scroll pr-5 mr-n5" style="max-height: 90vh; position: relative;">
          <template v-if="notifications.length">
            <KTTimelineList :items="notifications.slice(0, 5)" />

            <div class="text-center mt-9">
              <b-btn variant="primary" @click="closeAndViewMore">
                View More
              </b-btn>
            </div>
          </template>
          <div v-else>
            There is no recent activities
          </div>
        </perfect-scrollbar>
      </div>
      <!-- end::Quick Panel -->
    </template>
  </div>
</template>

<script>
import KTLayoutQuickPanel from '@/assets/js/layout/extended/quick-panel.js';
import KTTimelineList from '@/components/generic/TimelineList';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'KTQuickPanel',
  components: {
    KTTimelineList,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications/items',
      notificationsPagination: 'notifications/pagination',
    }),
  },
  mounted() {
    // Init Quick Offcanvas Panel
    KTLayoutQuickPanel.init(this.$refs['kt_quick_panel']);
  },
  methods: {
    closeAndViewMore() {
      const overlay = document.querySelector('.offcanvas-overlay');
      if (overlay) overlay.click();
      console.log('this.$route.name :>> ', this.$route.name);
      if (this.$route.name != 'notifications') {
        this.$router.push({ name: 'notifications' });
      }
    },
  },
};
</script>
