<template>
  <!-- begin:: Footer -->
  <div class="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">
    <div class="d-flex align-items-center justify-content-between" v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }">
      <div class="text-dark">
        <router-link to="/">
          <div class="d-flex align-items-center text-dark text-hover-primary">
            <span class="mr-4">
              <img :src="siteLogo()" alt="Logo" width="20" />
            </span>
            <span class="text-muted font-weight-bold mr-2">2020&copy;</span>
            App Express
          </div>
        </router-link>
      </div>
      <div v-if="isCoordinator || isBoardmember" class="nav nav-dark">
        <router-link to="/contacts" v-slot="{ href, navigate }">
          <a :href="href" class="nav-link pl-3 pr-0" @click="navigate">
            Contact
          </a>
        </router-link>
      </div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from 'vuex';
import objectPath from 'object-path';

export default {
  name: 'KTFooter',
  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig('brand.self.theme');
      // set brand logo
      const logoObject = this.layoutConfig('self.logo');

      let logo;
      if (typeof logoObject === 'string') {
        logo = logoObject;
      }
      if (typeof logoObject === 'object') {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + '');
      }
      if (typeof logo === 'undefined') {
        const logos = this.layoutConfig('self.logo');
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
  },
  computed: {
    ...mapGetters({
      layoutConfig: 'config/layoutConfig',
    }),
    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig('footer.width') === 'fluid';
    },
  },
};
</script>
