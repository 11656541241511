<template>
  <!-- begin:: Header Topbar -->
  <div class="topbar ml-auto">
    <!--begin: Quick panel toggle -->
    <KTQuickPanel></KTQuickPanel>
    <!--end: Quick panel toggle -->

    <!--begin: Quick Actions -->
    <b-dropdown size="sm" variant="link" toggle-class="topbar-item text-decoration-none" no-caret right no-flip>
      <template v-slot:button-content>
        <div class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2">
          <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
          <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ first_name || email }}</span>
          <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
            <span class="symbol-label font-size-h5 font-weight-bold">{{ first_name[0] || '' }}</span>
          </span>
        </div>
      </template>
      <b-dropdown-text tag="div" class="p-6">
        <KTDropdownQuickAction></KTDropdownQuickAction>
      </b-dropdown-text>
    </b-dropdown>
    <!--end: Quick Actions -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTDropdownQuickAction from '@/components/generic/DropdownQuickAction';
import KTQuickPanel from '@/components/generic/QuickPanel';
import { mapState } from 'vuex';

export default {
  name: 'KTTopbar',
  components: {
    KTDropdownQuickAction,
    KTQuickPanel,
  },
  computed: {
    first_name() {
      return this.user.first_name || '';
    },
    email() {
      return this.user.email || '';
    },
    ...mapState({
      user: (state) => state.auth.user || [],
    }),
  },
};
</script>
