<template>
  <div class="timeline timeline-3">
    <div class="timeline-items">
      <template v-for="(item, i) in items">
        <KTTimelineItem
          v-bind:key="i"
          :title="item.title"
          :date="item.created_at | moment('LLLL')"
          :tags="item.tags"
          :description="item.text"
          :icon="item.icon"
          :icon-variant="item.icon_color"
        ></KTTimelineItem>
      </template>
    </div>
  </div>
</template>

<script>
import KTTimelineItem from '@/components/generic/TimelineItem';

export default {
  name: 'KTTimelineList',
  components: {
    KTTimelineItem,
  },
  props: {
    items: Array,
  },
};
</script>
