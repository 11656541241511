<template>
  <ul v-if="!isApplicant" class="menu-nav">
    <router-link to="/" v-slot="{ href, navigate }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[$route.name == 'dashboard' ? 'menu-item-active' : '']">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Dashboard </span>
        </a>
      </li>
    </router-link>
    <router-link v-if="isCoordinator" to="/cooperatives" v-slot="{ href, navigate, isActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Cooperatives </span>
        </a>
      </li>
    </router-link>
    <router-link to="/applications" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Applications </span>
        </a>
      </li>
    </router-link>
    <router-link to="/contacts" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Contacts </span>
        </a>
      </li>
    </router-link>
    <router-link to="/transactions" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Transactions </span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'KTMenu',
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
  },
};
</script>
