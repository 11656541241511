<template>
  <div v-if="user">
    <!--begin::Header-->
    <div class="d-flex align-items-center">
      <div class="symbol symbol-100 mr-5">
        <div class="symbol-label" style="background-image:url('media/users/300_21.jpg')"></div>
        <i class="symbol-badge bg-success"></i>
      </div>
      <div class="d-flex flex-column">
        <div class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
          <template v-if="user.first_nam || user.last_name">
            {{ user.first_name + ' ' + user.last_name }}
          </template>
          <template v-else>
            -
          </template>
        </div>
        <div class="navi mt-2">
          <a href="#" class="navi-item">
            <span class="navi-link p-0 pb-2">
              <span class="navi-icon mr-1">
                <span class="svg-icon svg-icon-lg svg-icon-primary">
                  <inline-svg src="/all_media/svg/icons/Communication/Mail-notification.svg" />
                </span>
              </span>
              <span v-if="user.email" class="navi-text text-muted text-hover-primary">{{ user.email }}</span>
            </span>
          </a>
          <a @click="logout" class="btn btn-sm btn-light-primary font-weight-bolder py-2 px-5">Sign Out</a>
        </div>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Separator-->
    <div class="separator separator-dashed mt-8 mb-5"></div>
    <!--end::Separator-->

    <!--begin::Nav-->
    <div class="navi navi-spacer-x-0 p-0">
      <!--begin::Item-->
      <router-link :to="{ name: profileRoute }" v-slot="{ href, navigate }">
        <a :href="href" class="navi-item" @click="navigate">
          <div class="navi-link">
            <div class="symbol symbol-40 bg-light mr-3">
              <div class="symbol-label">
                <span class="svg-icon svg-icon-md svg-icon-success">
                  <inline-svg src="media/svg/icons/General/Notification2.svg" />
                </span>
              </div>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">
                My Profile
              </div>
              <div class="text-muted">
                Account settings and more
              </div>
            </div>
          </div>
        </a>
      </router-link>

      <!--end:Item-->

      <a :href="stripeLink" class="navi-item" v-if="isStripeLink" target="_blank">
          <div class="navi-link">
            <div class="symbol symbol-40 bg-light mr-3">
              <div class="symbol-label">
                <i class="fab fa-cc-stripe stripeColor"></i>
              </div>
            </div>
            <div class="navi-text">
              <div class="font-weight-bold">
                Stripe Account
              </div>
              <div class="text-muted">
                Account information
              </div>
            </div>
          </div>
        </a>
    </div>
    <!--end::Nav-->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { LOGOUT } from '@/store/modules/auth';
import store from '@/store';

export default {
  name: 'KTDropdownQuickAction',
  methods: {
    logout() {
      store.dispatch(LOGOUT);
      this.$router
        .push({ name: 'login' })
        .then((result) => {
          location.reload();
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user || null,
    }),
    profileRoute() {
      let name = 'profile-personal-information';
      if (this.isBoardmember) name = 'profile-transactions';
      if (this.isApplicant) name = 'profile-settings';
      return name;
    },
    isStripeLink(){
      return this.isCoordinator && this.user.stripe_dashboard_link != null
    },
    stripeLink(){
      return this.user.stripe_dashboard_link
    }
  },
};
</script>
<style scoped>
  .stripeColor{
    color:#1bc5bd;
  }
</style>