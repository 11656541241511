<template>
  <div class="timeline-item" :class="{ 'timeline-item-unstyled': !icon }">
    <div class="timeline-media">
      <i v-if="icon" :class="`${icon} text-${iconVariant}`"></i>
    </div>
    <div class="timeline-content">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="mr-2">
          <span class="text-dark-75 font-weight-bold">
            {{ title }}
          </span>
          <br />
          <span v-if="date" class="text-muted mr-2">
            {{ date }}
          </span>
          <template v-for="(tag, index) in tags">
            <span class="label label-light-primary font-weight-bolder label-inline" :key="index">{{ tag }}</span>
          </template>
        </div>
      </div>
      <p v-if="description" class="p-0 white-space-pre-line">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KTTimelineItem',
  props: {
    title: String,
    date: String,
    tags: Array,
    description: String,
    icon: String,
    iconVariant: String,
  },
};
</script>
<style lang="scss" scoped>
.white-space-pre-line {
  white-space: pre-line;
}
</style>
