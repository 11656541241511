<template>
  <div class="subheader py-2 py-lg-4 mb-7 mb-lg-0" v-bind:class="subheaderClasses" id="kt_subheader">
    <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap" v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }">
      <div class="d-flex align-items-center flex-wrap mr-1">
        <router-link v-if="$route.meta.backUrl" :to="$route.meta.backUrl">
          <div class="text-dark text-hover-primary mr-8">
            <i class="la la-arrow-left font-size-h1"></i>
          </div>
        </router-link>
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ newHeader || $route.meta.title }}
        </h5>
        <!--begin::Page Subtitle-->
        <template v-if="$route.meta.subtitle">
          <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
          <span class="text-muted font-weight-bold mr-4">
            {{ $route.meta.subtitle }}
          </span>
        </template>

        <!--end::Page Subtitle-->
      </div>

      <div class="d-flex align-items-center">
        <slot name="toolbar" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'KTSubheader',
  data() {
    return {
      newHeader: '',
    };
  },
  watch: {
    $route() {
      this.newHeader = '';
    },
  },
  mounted() {
    this.$root.$on('changeTitle', this.changeTitle);
  },
  beforeDestroy() {
    this.$root.$off('changeTitle', this.changeTitle);
  },
  methods: {
    changeTitle(title) {
      this.newHeader = title;
    },
  },
  computed: {
    ...mapGetters({
      layoutConfig: 'config/layoutConfig',
    }),
    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig('subheader.width') === 'fluid';
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig('subheader.style');
      if (style) {
        classes.push(style);

        if (style === 'solid') {
          classes.push('bg-white');
        }

        if (this.layoutConfig('subheader.fixed')) {
          classes.push('border-top');
        }
      }
      return classes.join(' ');
    },
  },
};
</script>
<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
