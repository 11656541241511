<template>
  <!-- begin:: Header -->
  <div id="kt_header" ref="kt_header" class="header" v-bind:class="headerClasses">
    <div class="container d-flex align-items-stretch">
      <!--begin::Logo Wrapper-->
      <div class="d-none d-lg-flex align-items-center mr-md-10">
        <!--begin::Logo-->
        <router-link to="/">
          <img :src="siteLogo()" alt="Logo" width="35" />
        </router-link>
        <!--end::Logo-->
      </div>
      <!--end::Logo Wrapper-->

      <!-- begin:: Header Menu -->
      <div class="header-menu-wrapper header-menu-wrapper-left" ref="kt_header_menu_wrapper">
        <div v-if="headerMenuEnabled && headerMenuShow" id="kt_header_menu" ref="kt_header_menu" class="header-menu header-menu-mobile" v-bind:class="headerMenuClasses">
          <!-- example static menu here -->
          <KTMenu></KTMenu>
        </div>
      </div>
      <!-- end:: Header Menu -->
      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from 'vuex';
import KTTopbar from '@/components/blocks/SubheaderTopbar';
import KTLayoutHeader from '@/assets/js/layout/base/header.js';
import KTLayoutHeaderMenu from '@/assets/js/layout/base/header-menu.js';
import KTMenu from '@/components/blocks/HeaderMenu';
import objectPath from 'object-path';

export default {
  name: 'KTHeader',
  components: {
    KTTopbar,
    KTMenu,
  },
  props: {
    headerMenuShow: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init('kt_header', 'kt_header_mobile');

    // Init Header Menu
    KTLayoutHeaderMenu.init(this.$refs['kt_header_menu'], this.$refs['kt_header_menu_wrapper']);
  },
  methods: {
    siteLogo() {
      const menuAsideLeftSkin = this.layoutConfig('brand.self.theme');
      // set brand logo
      const logoObject = this.layoutConfig('self.logo');

      let logo;
      if (typeof logoObject === 'string') {
        logo = logoObject;
      }
      if (typeof logoObject === 'object') {
        logo = objectPath.get(logoObject, menuAsideLeftSkin + '');
      }
      if (typeof logo === 'undefined') {
        const logos = this.layoutConfig('self.logo');
        logo = logos[Object.keys(logos)[0]];
      }
      return process.env.BASE_URL + logo;
    },
  },
  computed: {
    ...mapGetters({
      getClasses: 'htmlclass/getClasses',
      layoutConfig: 'config/layoutConfig',
    }),
    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig('header.menu.self.display');
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses('header');
      if (typeof classes !== 'undefined') {
        return classes.join(' ');
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses('header_menu');
      if (typeof classes !== 'undefined') {
        return classes.join(' ');
      }
      return null;
    },
  },
};
</script>
